import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
// import $ from "jquery";
import 'popper.js/dist/popper.min.js';
import './assets/css/main.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'
import * as amplitude from '@amplitude/analytics-browser';


amplitude.init(
  process.env.NODE_ENV === "production"
    ? "9f859e45759162c5541a2b67877f90b9"
    : "71da3f91f350eef570c4a068d2f0b367"
);
const tagManagerArgs = {
    gtmId: 'GTM-TRFX63D'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
