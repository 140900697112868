import React, { Fragment } from "react";
import './footer.css'
import { WEB_APP_URL } from "../../configs/environments";
import { trackSocials } from "../../Amplitute/trackFunctions";

const Index = () => {
  return (
    <Fragment>
      <section className="bg__blue pt-5 pb-2">
        <div className="container-md">
          <div className="row">
            <div className="col-lg-3 text-center text-lg-left pb-4 pb-lg-0">
              <a href="#!">
                <img
                  src="./../images/retickin_black_horizontal 1.png"
                  alt=""
                  className="image__140"
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
              <h4 className="bold font__size--16 text__16-1024 color__white mt__u7 mb-4">
                ABOUT US
              </h4>
              <p className="semi-bold font__size--12 color__white opacity__6">
              ReTickin’ is a community based platform connecting buyers and sellers of last minute tickets
              </p>
            </div>
            <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
              <h4 className="bold font__size--16 text__16-1024 color__white mt__u7 mb-4">
                LOCATION
              </h4>
              <p className="semi-bold font__size--12 color__white opacity__6">
                RETICKIN LIMITED, 3RD FLOOR, 86-90 PAUL STREET, LONDON, EC2A 4NE
              </p>
            </div>
            <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
            <a
                href={WEB_APP_URL}
                className="btn btn__red questionare shadow btn__find mt-4 bold font__size--16 text__16-1024 color__white"
              >
                View last minute events!
              </a>
            </div>
          </div>
<div className="socials">
  <a target="_blank" rel="noopener noreferrer" onClick={()=>trackSocials('Twitter','Footer',window.location.href)} href="https://twitter.com/retickinapp"><img src="/twitter.svg" alt="twitter" /></a>
  <a target="_blank" rel="noopener noreferrer" onClick={()=>trackSocials('Linkedin','Footer',window.location.href)} href="https://www.linkedin.com/company/retickinapp/"><img src="/linkedIn.svg" alt="LinkedIn" /></a>
  <a target="_blank" rel="noopener noreferrer" onClick={()=>trackSocials('Instagram','Footer',window.location.href)} href="https://instagram.com/retickinapp"><img src="/insta.svg" alt="insta" /></a>
</div>
          <hr className="hr__line mt-4 mb-0" />
          <h5 className="text-center bold font__size--12 color__white opacity__6 lh-2 pt-4">
            ReTickin' ™ - On a mission to ensure that no experience goes to waste, since 2022.
          </h5>
        </div>
      </section>
    </Fragment>
  );
};

export default Index;
