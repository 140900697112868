import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "../../component/navbar/Index";
import Footer from "./../../component/footer/index";
import { track } from "@amplitude/analytics-browser";

export default class index extends Component {
  componentDidMount() {
    track('View Page',{
      "Page Url ":window.location.href,
      "Page Url" : "Sell Page"
    });
    AOS.init({
      once: true,
    });
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>ReTickin' - Sell</title>
          <meta name="title" content="ReTickin' - Sell" />
          <meta
            name="description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="" />
          <meta property="og:title" content="ReTickin' - Sell" />
          <meta
            property="og:description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />
          <meta
            property="og:image"
            content="https://www.retickin.com/images/retickinlogotwitter.png"
          />

          <meta property="twitter:url" content="" />
          <meta property="twitter:title" content="ReTickin' - Sell" />
          <meta
            property="twitter:description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />
          {/* <meta
            property="twitter:image"
            content="https://www.retickin.com/images/retickinlogotwitter.png?123"
          ></meta> */}
        </Helmet>
        <Navbar />
        <section
          className="wrapper__nextpage bg__blue position-relative d-flex justify-content-center align-items-center z-2"
          id="click-backtoTop"
        >
          <div className="container position-relative z-3 w-100 my-5">
            <div className="text-center">
              <h1
                className="color__white bold font__size--65 text__65-1024 text__65-sm d-flex justify-content-center flex-wrap align-items-center"
                data-aos-delay="700"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                <img
                  src="./../images/retickin_black_horizontal 1.png"
                  className="mr-3 mb-3 mb-lg-0 image__logo-text"
                  alt=""
                />{" "}
                is not quite ready yet!
              </h1>
            </div>

            <div className="row justify-content-center mt-5">
              <div className="col-lg-10 col-xl-9">
                <div
                  className="wrapper__text-content bg__white text-center"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                  data-aos="zoom-in"
                >
                  <h5 className="extra-bold font__size--20 text__20-1024-18 text__20-md color__black mb-3 lh-2 opacity__7">
                    We’d love to help you sell your last minute ticket today,
                    but we are not quite there yet!
                  </h5>
                  <p className="lh-2 semi-bold font__size--18 text__18-1024 text__18-xs color__gray-2 mb-4">
                    Restrictions are easing, we’re going to be getting our lives
                    back, and our diaries are soon to be filled with things
                    other than ‘walks in the park’. HELL TO THE YEAH.
                  </p>
                  <p className="lh-2 semi-bold font__size--18 text__18-1024 text__18-xs color__gray-2 mb-4">
                    When we’re allowed out, where are you headed? Take our quiz
                    so we know more about what events suit you and be the first
                    to know when the ReTickin’ App drops!
                  </p>
                  <div className="text-center">
                    <a
                      href="https://research.typeform.com/to/Qmv4zjOk"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn__red btn__question color__white shadow bold font__size--16 text__16-1024 text__16-md lh-2"
                    >
                      Take part in the ReTickin’ Questionnaire
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}
