import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Anime from "animejs/lib/anime.es.js";
import { $ } from "react-jquery-plugin";
import { TweenMax } from "gsap";

import AOS from "aos";
import "aos/dist/aos.css";
import "../../assets/css/review-style.css";
import "../../assets/css/review-media.css";

import Navbar from "../../component/navbar/Index";
import { WEB_APP_URL } from "../../configs/environments";
import { trackSocials } from "../../Amplitute/trackFunctions";
import { track } from "@amplitude/analytics-browser";

export default class index extends Component {
  componentDidMount() {
    track('View Page',{
      "Page Url ":window.location.href,
      "Page Name":"Review Page"
    });
    AOS.init({
      once: true,
    });
    console.log(window.location.href)
    // animation
    Anime({
      targets: ".path__4 img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".path__3 img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step.one .icon.one-x img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.three img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.four img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-side .icon img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.six img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.five img",
      rotate: 360,
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    // animation

    // transition
    $(window).on("load", function () {
      TweenMax.from(".wrapper__image-side .phone", {
        scaleX: 0,
        scaleY: 0,
        opacity: 0,
        duration: 1.5,
        delay: 1.5,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>ReTickin' - How it Works</title>
          <meta name="title" content="ReTickin' - Sell" />
          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
          <meta
            name="description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="" />
          <meta property="og:title" content="ReTickin' - Sell" />
          <meta
            property="og:description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />
          <meta
            property="og:image"
            content="https://www.retickin.com/images/retickinlogotwitter.png"
          />
          <meta property="twitter:url" content="" />
          <meta property="twitter:title" content="ReTickin' - Sell" />
          <meta
            property="twitter:description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />
          {/* <meta
            property="twitter:image"
            content="https://www.retickin.com/images/retickinlogotwitter.png?123"
          ></meta> */}
        </Helmet>
        <div className="overflow-hidden">
        <Navbar />
        <div
            className="section__head position-relative bg__blue overflow-hidden"
            id="click-backtoTop"
          />
        <section id="frist">
        <div class="container">
            <br/><br/><br/>
            <div class="row">
                <div class="col-lg-12 text-center">
                 <div class="first">
                    <h2>
                        Thank you for letting us know that everything was okay!
                    </h2>
                    <br/>
                    <p>
                        <span>We’re glad that you had a good experience!</span> As we are constantly learning and <br/> evolving the platform, we would appreciate if you could leave us some feedback.
                    </p>
                    <br/>
                    <div class="btna">
                        <a href="https://tally.so/r/mVGKrl">
                            Feedback form
                        </a>
                    </div>
                 </div>
                </div>
            </div>
        </div>
     </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="first">
              <h2>
              Review ReTickin’ with Trustpilot
              </h2>
              <p>
              You're wayyyy ahead of the curve by finding us first, so please could you take the time to leave a <br/> review on Trustpilot, as it will help other fans who will discover ReTickin’ after you
              </p>
              <div class="btnb">
                <div  iv class="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="62ced91863bf0e6c947002af" data-style-height="52px" data-style-width="100%">
                  <a href="https://uk.trustpilot.com/review/retickin.com" target="_blank" rel="noreferrer">Trustpilot</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </section>

     <section id="footerr">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-12">
                    <div class="footerr-main text-center">
                        <h5>
                            The last minute marketplace. <span>On a mission to ensure that no experience goes to waste!</span>
                        </h5>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 text-center">
                    <div class="btna">
                        <a href="https://www.retickin.com/">
                            www.retickin.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
     </section>
     </div>
     <section className="bg__blue pt-5 pb-2">
          <div className="container-md">
            <div className="row">
              <div className="col-lg-3 text-center text-lg-left pb-4 pb-lg-0">
                <a href="#!">
                  <img
                    src="./../images/retickin_black_horizontal 1.png"
                    alt=""
                    className="image__140"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
                <h4 className="bold font__size--16 text__16-1024 color__white mt__u7 mb-4">
                  ABOUT US
                </h4>
                <p className="semi-bold font__size--12 color__white opacity__6">
                  ReTickin’ is a community based platform connecting buyers and
                  sellers of last minute tickets
                </p>
              </div>
              <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
                <h4 className="bold font__size--16 text__16-1024 color__white mt__u7 mb-4">
                  LOCATION
                </h4>
                <p className="semi-bold font__size--12 color__white opacity__6">
                  RETICKIN LIMITED, 3RD FLOOR, 86-90 PAUL STREET, LONDON, EC2A
                  4NE
                </p>
              </div>
              <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
                <a
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                  href={WEB_APP_URL}
                  className="btn btn__red questionare shadow btn__find mt-4 bold font__size--16 text__16-1024 color__white"
                  style={{ width: "max-content" }}
                >
                  View last minute events!
                </a>
              </div>
            </div>
            <div className="socials">
              <a
              target="_blank" rel="noopener noreferrer"
              onClick={()=>trackSocials('Twitter','Footer',window.location.href)}
              href="https://twitter.com/retickinapp">
                <img src="/twitter.svg" alt="twitter" />
              </a>
              <a 
              target="_blank" rel="noopener noreferrer"
              onClick={()=>trackSocials('Linkedin','Footer',window.location.href)}
              href="https://www.linkedin.com/company/retickinapp/">
                <img src="/linkedIn.svg" alt="LinkedIn" />
              </a>
              <a 
              target="_blank" rel="noopener noreferrer"
              onClick={()=>trackSocials('Instagram','Footer',window.location.href)}
              href="https://instagram.com/retickinapp">
                <img src="/insta.svg" alt="insta" />
              </a>
            </div>
            <hr className="hr__line mt-4 mb-0" />
            <h5 className="text-center bold font__size--12 color__white opacity__6 lh-2 pt-4">
              ReTickin' ™ - On a mission to ensure that no experience goes to waste, since 2022.
            </h5>
          </div>
        </section>     
      </Fragment>
    );
  }
}
