import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { hotjar } from 'react-hotjar';

import Homepage from "./homepage/index";
import Buy from "./buy/index";
import Sell from "./sell/Index";
import How from "./how/index";
import Review from "./review/index";

export default class index extends Component {

  componentDidMount() {
    hotjar.initialize(process.env.REACT_APP_HOT_JAR_ID, process.env.REACT_APP_HOT_JAR_SV);
  }

  render() {
    return (
      <Fragment>
        <Router forceRefresh>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route path="/buy">
              <Buy />
            </Route>
            <Route path="/sell">
              <Sell />
            </Route>
            <Route path="/how-it-works">
              <How />
            </Route>
            <Route path="/review">
              <Review />
            </Route>
          </Switch>
        </Router>
      </Fragment>
    );
  }
}
