import React, { useEffect, Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { $ } from "react-jquery-plugin";
import { track } from "@amplitude/analytics-browser";
import { trackSocials } from "../../Amplitute/trackFunctions";

const Index = (params) => {
  const [scrolled, setScrolled] = React.useState(false);
  const [backtotop, setBacktotop] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 130) {
      setScrolled(true);
      setBacktotop(true);
    } else {
      setScrolled(false);
      setBacktotop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $(".responsive__fixed").removeClass("show");
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, []);
  return (
    <Fragment>
      <a
        href="#click-backtoTop"
        className={"wrapper__to-up " + (backtotop ? "active" : "")}
      >
        <svg
          width="22"
          height="15"
          viewBox="0 0 22 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9999 0.318359L0.408447 10.9084L3.59145 14.0914L10.9999 6.68136L18.4084 14.0914L21.5914 10.9084L10.9999 0.318359Z"
            fill="white"
          />
        </svg>
      </a>
      <div className="position-relative z-3">
        <nav
          className={
            "navbar navbar-expand-lg navbar__group px-lg-0 " +
            (scrolled ? "active" : "")
          }
        >
          <div className="container px-lg-0">
            <NavLink
              to="/"
              className="navbar-brand position-relative z-3"
              href="#"
              onClick={()=>{
                track('Logo Click',{
                  "Page Url":window.location.href,
                })
              }}
            >
              <img
                src="./../images/retickin_black_horizontal 1.png"
                alt="image_140"
                className="image__140"
              />
            </NavLink>
            <button
              className="navbar-toggler navbar__button position-relative z-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div
              className="collapse navbar-collapse nav__menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
              <li className="nav-item">

                    <a
                     onClick={()=> track('Register Events',{
                      "Page Url":window.location.href, 
                    })}
                      target="_blank" rel="noopener noreferrer"
                      href="https://research.typeform.com/to/KZ2xRSXP"
                      className="nav-link color__white font__size--14 text__14-1024 extra-bold"
                    >
                      <span className="d-inline-block border__underline">
                        Register Your Event
                      </span>
                    </a>
                  </li>
                <li className="nav-item">
                  <NavLink
                    onClick={()=> track('Click How it works',{
                      "Position":"Header",
                      "Page Url":window.location.href, 
                    })}
                    to="/how-it-works"
                    className="nav-link color__white font__size--14 text__14-1024 extra-bold"
                  >
                    <span className="d-inline-block border__underline">
                      How it Works
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                   onClick={()=>trackSocials('Twitter','Header',window.location.href)}
                    href="https://twitter.com/retickinapp"
                    target="_blank"
                    rel="noreferrer"
                    className="nav-link btn__link color__white font__size--14 text__14-1024 bold twitter"
                  >
                    <img
                      src="./../images/Frame.png"
                      className="image__18"
                      alt=""
                    />{" "}
                    Twitter
                  </a>
                </li>
                <li className="nav-item">
                  <a
                   onClick={()=>trackSocials('Instagram','Header',window.location.href)}
                    href="https://www.instagram.com/retickinapp/"
                    target="_blank"
                    rel="noreferrer"
                    className="nav-link btn__link color__white font__size--14 text__14-1024 bold instagram"
                  >
                    <img
                      src="./../images/Frame sdasd.png"
                      className="image__18"
                      alt=""
                    />{" "}
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Index;
