import { track } from '@amplitude/analytics-browser';

export const trackSocials = (socialType,position,url) =>{
    //get current url from browser
    //const url = window.location.href;
    track('Socials',{
         "Social Type": socialType,
         "Position" : position,
         "Page Url": url,
    })
}