import React, { Fragment, useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Anime from "animejs/lib/anime.es.js";
import { $ } from "react-jquery-plugin";
import { TweenMax } from "gsap";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import MainSection from "../../component/MainSection";
import { WEB_APP_URL } from "../../configs/environments";
import { trackSocials } from "../../Amplitute/trackFunctions";
import { track } from "@amplitude/analytics-browser";

const Index = (params) => {
  let interval = useRef();
  let typeFormPopUpRef = useRef();

  const countDown = (format) => {
    const date = "2021-10-1 23:59:59";
    const dateInFuture = Date.parse(date.replace(/-/g, "/"));
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = dateInFuture - now;

      if (distance < 0) {
        clearInterval(interval.current);
      }
    }, 1000);
  };

  const [scrolled, setScrolled] = useState(false);
  const [backtotop, setBacktotop] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 130) {
      setScrolled(true);
      setBacktotop(true);
    } else {
      setScrolled(false);
      setBacktotop(false);
    }
  };

  useEffect(() => {
    track('View Page',{
      "Page Url": window.location.href,
      "Page Name": "Landing Page",
    })
    countDown();

    AOS.init({
      once: true,
    });

    Anime({
      targets: ".icon__3 img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });

    Anime({
      targets: ".icon__2 img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });

    Anime({
      targets: ".icon__10 img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });

    Anime({
      targets: ".icon__9 img",
      keyframes: [{ rotate: -15 }, { rotate: 15 }, { rotate: -15 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });

    $(window).on("load", function () {
      TweenMax.to(".icon__8", {
        xPercent: -50,
        left: "0%",
        top: "40%",
        rotate: -15,
        opacity: 1,
        duration: 1.5,
        delay: 1.5,
      });
      TweenMax.to(".icon__9", {
        xPercent: -50,
        right: "-2%",
        top: "25%",
        rotate: 15,
        opacity: 1,
        duration: 1.5,
        delay: 1.5,
      });
      TweenMax.to(".icon__10", {
        xPercent: -50,
        left: "7%",
        top: "75%",
        rotate: -20,
        opacity: 1,
        duration: 1.5,
        delay: 1.5,
      });
      TweenMax.to(".icon__11", {
        xPercent: -50,
        right: "-2%",
        top: "67%",
        rotate: 15,
        opacity: 1,
        duration: 1.5,
        delay: 1.5,
      });
    });

    window.addEventListener("scroll", handleScroll);

    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $(".responsive__fixed").removeClass("show");
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, [typeFormPopUpRef]);

  return (
    <Fragment>
      <Helmet>
        <title>ReTickin'</title>
        <meta name="title" content="ReTickin'" />
        <meta
          name="description"
          content="The last minute marketplace, on a mission to ensure that no experience goes to waste!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
        <meta property="og:title" content="ReTickin'" />
        <meta
          property="og:description"
          content="The last minute marketplace, on a mission to ensure that no experience goes to waste!"
        />
        <meta
          property="og:image"
          content="https://www.retickin.com/images/retickinlogotwitter.png"
        />

        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="ReTickin'" />
        <meta
          property="twitter:description"
          content="The last minute marketplace, on a mission to ensure that no experience goes to waste!"
        />
        {/* <meta
          property="twitter:image"
          content="https://www.retickin.com/images/retickinlogotwitter.png?123"
        ></meta> */}
      </Helmet>

      <div className="overflow-hidden modal-open">
        <a
          href="#click-backtoTop"
          className={"wrapper__to-up " + (backtotop ? "active" : "")}
        >
          <svg
            width="22"
            height="15"
            viewBox="0 0 22 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9999 0.318359L0.408447 10.9084L3.59145 14.0914L10.9999 6.68136L18.4084 14.0914L21.5914 10.9084L10.9999 0.318359Z"
              fill="white"
            />
          </svg>
        </a>
        <div className="position-relative z-3">
          <nav
            className={
              "navbar navbar-expand-lg navbar__group px-lg-0 " +
              (scrolled ? "active" : "")
            }
          >
            <div className="container px-lg-0">
              <a className="navbar-brand position-relative z-3" href="/" onClick={()=>{
                track('Logo Click',{
                  "Page Url":window.location.href,
                })
              }}>
                <img
                  alt=""
                  src="./../images/retickin_black_horizontal 1.png"
                  className="image__140"
                />
              </a>
              <button
                //onClick={()=>track('Hamburger Menu Open')}
                className="navbar-toggler navbar__button position-relative z-3"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <div
                className="collapse navbar-collapse nav__menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      target="_blank" rel="noopener noreferrer"
                      onClick={()=> track('Register Events',{
                        "Page Url":window.location.href, 
                      })}
                      href="https://research.typeform.com/to/KZ2xRSXP"
                      className="nav-link color__white font__size--14 text__14-1024 extra-bold"
                    >
                      <span className="d-inline-block border__underline">
                        Register Your Event
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavLink
                       onClick={()=> track('Click How it works',{
                        "Position":"Header",
                        "Page Url":window.location.href, 
                      })}
                      to="/how-it-works"
                      className="nav-link color__white font__size--14 text__14-1024 extra-bold"
                    >
                      <span className="d-inline-block border__underline">
                        How it Works
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={()=>trackSocials('Twitter','Header',window.location.href)}
                      href="https://twitter.com/retickinapp"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link btn__link color__white font__size--14 text__14-1024 bold twitter"
                    >
                      <img
                        src="./../images/Frame.png"
                        className="image__18"
                        alt=""
                      />{" "}
                      Twitter
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                     onClick={()=>trackSocials('Instagram','Header',window.location.href)}
                      href="https://www.instagram.com/retickinapp/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link btn__link color__white font__size--14 text__14-1024 bold instagram"
                    >
                      <img
                        src="./../images/Frame sdasd.png"
                        className="image__18"
                        alt=""
                      />{" "}
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div
          className="section__head position-relative bg__blue overflow-hidden"
          id="click-backtoTop"
        >
          <div className="w-100">
            <section className="position-relative z-2">
              <div className="container">
                <div className="row pt__130">
                  <div className="col-lg-6">
                    <div className="text-center text-lg-left">
                      <div className="position-relative d-inline-block line__shape">
                        <span
                          data-aos-delay="8000"
                          data-aos-duration="1000"
                          data-aos="fade-left"
                          className="redBar"
                        ></span>
                        <h5
                          data-aos-delay="300"
                          data-aos-duration="1000"
                          data-aos="fade-up"
                          className="extra-bold font__size--60 text__60-1024 text__60-md text__60-xx text__60-xxs color__white m-0 position-relative z-1"
                        >
                          Do It Last Minute.{" "}
                          <br className="d-none d-sm-block" />{" "}
                        </h5>
                      </div>
                      <p
                        data-aos-delay="400"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                        className="medium font__size--18 text__18-1024 text__18-xs color__gray-4 lh-2 mt-2 mt-sm-4 mb-0"
                      >
                        The last minute marketplace, on a mission to ensure that no experience goes to waste!
                      </p>
                      <a
                        href={WEB_APP_URL}
                        className="btn btn__red shadow btn__find mt-4 bold font__size--16 text__16-1024 color__white"
                      >
                        View last minute events!
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 position-relative">
                    <div className="image__head">
                      <div
                        className="position-relative d-inline-block"
                        data-aos-delay="300"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <div className="d-none d-md-flex icon__8">
                          <img src="./../images/asa.png" alt="" />
                        </div>

                        <div className="d-none d-md-flex icon__9">
                          <img
                            src="./../images/aAaS.png"
                            className="image__max-30"
                            alt=""
                          />
                        </div>

                        <div className="d-none d-md-flex icon__10">
                          <img
                            src="./../images/SAASDASD.png"
                            className="image__max-30 "
                            alt=""
                          />
                        </div>

                        <div className="d-none d-md-flex icon__11">
                          <img src="./../images/hghtrth.png" alt="" />
                        </div>

                        <img
                          src="./../images/asadasdsasad.png"
                          className="position-relative phone z-2"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <MainSection />

        <section className="pt-lg-0 pb-lg-0 bg__gray-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-12 text-center text-lg-left my-auto">
                <h5
                  className="color__red ls__2 extra-bold font__size--14 text__14-1024 line__left mb-0 d-none d-lg-block"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  SELL WITH RETICKIN’
                </h5>
                <h4
                  className="extra-bold font__size--32 text__32-1024 text__32-sm text__32-xs color__black lh__5 my-4"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  If you can’t make it, sell your ticket to someone who can!
                </h4>

                <ul className="list__detail mb-4">
                  <li
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                    className="d-flex justify-content-lg-start justify-content-center text-left align-items-start semi-bold font__size--16 text__16-1024 color__gray-2 lh-2"
                  >
                    <img
                      alt=""
                      src="./../images/fssasfasfasf.png"
                      className="checklist mr-3"
                    />
                    <div>
                      In{" "}
                      <span className="extra-bold color__red">
                        3 simple steps
                      </span>
                      , list your ticket to people looking for last minute
                      tickets
                    </div>
                  </li>
                  <li
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                    className="d-flex justify-content-lg-start justify-content-center text-left align-items-start semi-bold font__size--16 text__16-1024 color__gray-2 lh-2"
                  >
                    <img
                      alt=""
                      src="./../images/fssasfasfasf.png"
                      className="checklist mr-3"
                    />
                    <div>
                      <span className="extra-bold color__red">
                        No seller fees!
                      </span>{" "}
                      - We believe you shouldn’t be charged because you can no
                      longer go to an event
                    </div>
                  </li>
                  <li
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                    className="d-flex justify-content-lg-start justify-content-center text-left align-items-start semi-bold font__size--16 text__16-1024 color__gray-2 lh-2"
                  >
                    <img
                      alt=""
                      src="./../images/fssasfasfasf.png"
                      className="checklist mr-3"
                    />
                    <div>
                      Our pricing algorithm automatically updates prices,{" "}
                      <span className="extra-bold color__red">
                        helping you sell your ticket and get the best payout
                      </span>
                    </div>
                  </li>
                </ul>

                <a
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                  href={WEB_APP_URL}
                  className="bold font__size--16 text__16-1024 color__white btn btn__red shadow btn__buy"
                >
                  View last minute events!
                </a>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 my-auto pb-lg-0 text-center text-lg-right">
                <h5
                  className="color__red ls__2 extra-bold font__size--14 text__14-1024 line__left mb-4 d-block d-lg-none"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  SELL WITH RETICKIN’
                </h5>
                <div
                  className="position-relative"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  data-aos="fade-left"
                >
                  <div className="icon__6">
                    <img src="./../images/saasdgg.png" alt="" />
                  </div>
                  <img
                    src="./../images/Group 740.png"
                    className="image__content image__max-485 center"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-0">
          <div className="container-fluid">
            <div className="text-center">
              <h4
                className="extra-bold font__size--32 text__32-1024 text__32-sm text__32-xs color__black lh__5 mb-4"
                data-aos-delay="300"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                Do you want some more information on <br />
                how ReTickin’ works?
              </h4>
              <p
                className="semi-bold font__size--18 text__18-1024 color__gray-2 opacity__7 lh-2"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                We have given you a taster for what we are all about!{" "}
                <br className="d-none d-md-block" />
                If you want find out some more information about how ReTickin’
                <br /> works, please take a look at our ‘How it works’ section!.
              </p>
              <br />

              <NavLink
               onClick={()=> track('Click How it works',{
                "Position":"Body",
                "Page Url":window.location.href, 
              })}
                to="/how-it-works"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos="fade-up"
                href="#!"
                className="bold font__size--16 text__16-1024 color__white btn btn__red shadow btn__buy"
              >
                How it Works
              </NavLink>
            </div>
          </div>
        </section>

        <section className="">
          <div className="container">
            <div className="wrapper__banner position-relative bg__blue">
              <img src="./../images/Mask Group (11).png" alt="" />

              <div
                className="icon__2 bg__white"
                data-aos-delay="100"
                data-aos-duration="1000"
                data-aos="zoom-in-down"
              >
                <img
                  src="./../images/ssa.png"
                  className="image__banner"
                  alt=""
                />
              </div>

              <div
                className="icon__3 bg__white"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos="zoom-in-down"
              >
                <img
                  src="./../images/sas.png"
                  className="image__banner"
                  alt=""
                />
              </div>

              <div className="text-center position-relative z-2">
                <div
                  className="icon__1 bg__red mx-auto mb-4"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  <img
                    src="./../images/3 User.png"
                    className="image__banner"
                    alt=""
                  />
                </div>
                <div
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  <h5 className="semi-bold font__size--14 text__14-1024 color__white opacity__5 ls__3 lh-2 mb-4">
                    THE FIRST THING PEOPLE SAY WHEN THEY HEAR ABOUT RETICKIN'
                  </h5>
                </div>

                <div className="row justify-content-center pt-2">
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-md-4 flex-fill mb-4 mb-md-0">
                        <div
                          data-aos-delay="500"
                          data-aos-duration="1000"
                          data-aos="flip-left"
                          className="wrapper__card h-100 semi-bold font__size--14 text__14-1024 font-italic color__white"
                        >
                          “This is an amazing idea, I can’t believe it doesn’t
                          exist already!”
                        </div>
                      </div>
                      <div className="col-md-4 flex-fill mb-4 mb-md-0">
                        <div
                          data-aos-delay="700"
                          data-aos-duration="1000"
                          data-aos="flip-left"
                          className="wrapper__card h-100 semi-bold font__size--14 text__14-1024 font-italic color__white"
                        >
                          “I wish this existed when I needed to sell my ticket
                          last minute!”
                        </div>
                      </div>
                      <div className="col-md-4 flex-fill mb-4 mb-md-0">
                        <div
                          data-aos-delay="8000"
                          data-aos-duration="1000"
                          data-aos="flip-left"
                          className="wrapper__card h-100 semi-bold font__size--14 text__14-1024 font-italic color__white"
                        >
                          “This is perfect, it really creates a win-win
                          situation for everyone involved!”
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg__blue pt-5 pb-2">
          <div className="container-md">
            <div className="row">
              <div className="col-lg-3 text-center text-lg-left pb-4 pb-lg-0">
                <a href="#!">
                  <img
                    src="./../images/retickin_black_horizontal 1.png"
                    alt=""
                    className="image__140"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
                <h4 className="bold font__size--16 text__16-1024 color__white mt__u7 mb-4">
                  ABOUT US
                </h4>
                <p className="semi-bold font__size--12 color__white opacity__6">
                  ReTickin’ is a community based platform connecting buyers and
                  sellers of last minute tickets
                </p>
              </div>
              <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
                <h4 className="bold font__size--16 text__16-1024 color__white mt__u7 mb-4">
                  LOCATION
                </h4>
                <p className="semi-bold font__size--12 color__white opacity__6">
                  RETICKIN LIMITED, 3RD FLOOR, 86-90 PAUL STREET, LONDON, EC2A
                  4NE
                </p>
              </div>
              <div className="col-md-6 col-lg-3 text-center text-md-left pb-4 pb-lg-0">
                <a
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                  href={WEB_APP_URL}
                  className="btn btn__red questionare shadow btn__find mt-4 bold font__size--16 text__16-1024 color__white"
                  style={{ width: "max-content" }}
                >
                  View last minute events!
                </a>
              </div>
            </div>
            <div className="socials">
              <a
              target="_blank" rel="noopener noreferrer"
              onClick={()=>trackSocials('Twitter','Footer',window.location.href)}
              href="https://twitter.com/retickinapp">
                <img src="/twitter.svg" alt="twitter" />
                
              </a>
              <a
              target="_blank" rel="noopener noreferrer"
              onClick={()=>trackSocials('Linkedin','Footer',window.location.href)}
              href="https://www.linkedin.com/company/retickinapp/">
                <img src="/linkedIn.svg" alt="LinkedIn" />
              </a>
              <a
              target="_blank" rel="noopener noreferrer"
              onClick={()=>trackSocials('Instagram','Footer',window.location.href)}
              href="https://instagram.com/retickinapp">
                <img src="/insta.svg" alt="insta" />
              </a>
            </div>
            <hr className="hr__line mt-4 mb-0" />
            <h5 className="text-center bold font__size--12 color__white opacity__6 lh-2 pt-4">
              ReTickin' ™ - On a mission to ensure that no experience goes to waste, since
              2022.
            </h5>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Index;
