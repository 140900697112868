import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Anime from "animejs/lib/anime.es.js";
import { $ } from "react-jquery-plugin";
import { TweenMax } from "gsap";

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "../../component/navbar/Index";
import Footer from "./../../component/footer/index";
import { WEB_APP_URL } from "../../configs/environments";
import { track } from "@amplitude/analytics-browser";

export default class index extends Component {
  componentDidMount() {
    track('View Page',{
      "Page Url ":window.location.href,
      "Page Name":"How It Works"
    });
    AOS.init({
      once: true,
    });

    // animation
    Anime({
      targets: ".path__4 img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".path__3 img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step.one .icon.one-x img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.three img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.four img",
      keyframes: [{ scale: 1.2 }, { scale: 1 }, { scale: 1.2 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-side .icon img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.six img",
      keyframes: [{ rotate: 30 }, { rotate: -30 }, { rotate: 30 }],
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    Anime({
      targets: ".wrapper__image-step .icon.five img",
      rotate: 360,
      loop: true,
      duration: 2000,
      easing: "linear",
    });
    // animation

    // transition
    $(window).on("load", function () {
      TweenMax.from(".wrapper__image-side .phone", {
        scaleX: 0,
        scaleY: 0,
        opacity: 0,
        duration: 1.5,
        delay: 1.5,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>ReTickin' - How it Works</title>
          <meta name="title" content="ReTickin' - Sell" />
          <meta
            name="description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="" />
          <meta property="og:title" content="ReTickin' - Sell" />
          <meta
            property="og:description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />
          <meta
            property="og:image"
            content="https://www.retickin.com/images/retickinlogotwitter.png"
          />

          <meta property="twitter:url" content="" />
          <meta property="twitter:title" content="ReTickin' - Sell" />
          <meta
            property="twitter:description"
            content="The last minute marketplace. On a mission to ensure that no experience goes to waste!"
          />
          {/* <meta
            property="twitter:image"
            content="https://www.retickin.com/images/retickinlogotwitter.png?123"
          ></meta> */}
        </Helmet>
        <div className="overflow-hidden">
          <Navbar />
          <div
            className="section__head position-relative bg__blue overflow-hidden"
            id="click-backtoTop"
          >
            <img
              src="./../images/Mask Group (1).png"
              className="path__6 d-none d-md-block"
              alt=""
              data-aos-delay="700"
              data-aos-duration="1000"
              data-aos="fade-up-right"
            />
            <div className="w-100">
              <section className="position-relative z-2">
                <div
                  className="path__3 d-none d-md-flex"
                  data-aos-delay="800"
                  data-aos-duration="1000"
                  data-aos="fade-down-right"
                >
                  <img src="./../images/dsa.png" alt="" />
                </div>
                <div
                  className="path__4 d-none d-md-flex"
                  data-aos-delay="900"
                  data-aos-duration="1000"
                  data-aos="fade-up-left"
                >
                  <img src="./../images/sadsa.png" alt="" />
                </div>
                <img
                  src="./../images/Mask Group.png"
                  className="path__5 d-none d-md-block"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  data-aos="fade-down-left"
                  alt=""
                />
                <div className="container">
                  <div className="row justify-content-center pt__130 pb-5">
                    <div className="col-md-10">
                      <div className="text-center">
                        <div className="position-relative d-inline-block line__shape-how">
                          <span
                            data-aos-delay="500"
                            data-aos-duration="1000"
                            data-aos="fade-left"
                          ></span>
                          <h5
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos="fade-up"
                            className="extra-bold font__size--60 text__60-1024 text__60-md text__60-xx text__60-xxs color__white m-0 position-relative z-1"
                          >
                            How it works
                          </h5>
                        </div>

                        <div className="d-block my-5">
                          <div
                            data-aos-delay="500"
                            data-aos-duration="1000"
                            data-aos="zoom-in-up"
                          >
                            <div className="wrapper__tag-line d-inline-block bg__red">
                              <h5 className="m-0 extra-bold font__size--16 text__16-1024 text__16-md text__16-sm text__16-mm text__16-xx-9 lh-2 color__white">
                                NO BUZZWORDS, NO JARGON... ReTickin’ is simple!{" "}
                              </h5>
                            </div>
                          </div>
                        </div>

                        <p
                          className="normal font__size--16 text__16-1024 text__16-md text__16-xxs color__gray-4 lh-2"
                          data-aos-delay="600"
                          data-aos-duration="1000"
                          data-aos="fade-up"
                        >
                          We are here to help last minute event goers{" "}
                          <br className="br__xx d-sm-none" />
                          <span className="position-relative line__heading bold color__white">
                            save money
                            <img src="./../images/Vector 1.png" alt="" />
                          </span>{" "}
                          <br className="br__xx-none d-lg-none" /> on tickets,
                          whilst allowing{" "}
                          <br className="d-none d-lg-block d-xl-none" /> ticket{" "}
                          <br className="br__xxs-none d-sm-none" />
                          holders who realise at the last minute they can’t
                          attend,{" "}
                          <br className="br__xxs-none br__400-none d-sm-none" />{" "}
                          one last shot at{" "}
                          <br className="br__xxs-none d-none d-lg-block d-xl-none" />
                          selling their tickets and <br className="d-lg-none" />{" "}
                          <span className="position-relative line__heading bold color__white">
                            recouping some of their hard earned cash!
                            <img src="./../images/Vector 2.png" alt="" />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <section>
            <div className="container">
              <div className="text-center mb-5">
                <h4
                  className="extra-bold font__size--32 text__32-1024 color__black mb-4"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  Buy with ReTickin’
                </h4>
                <h5
                  className="color__red ls__5 semi-bold font__size--14 text__14-1024 lh-2"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  HOW TO BUY WITH RETICKIN’
                </h5>
              </div>

              <div className="row justify-content-center">
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0 text-center position-relative"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                  data-aos="fade-right"
                >
                  <div className="wrapper__image-step one position-relative">
                    <img
                      src="./../images/Arrow 2.png"
                      className="d-none d-lg-block arrow"
                      alt=""
                      data-aos-delay="500"
                      data-aos-duration="1000"
                      data-aos="fade-right"
                    />
                    <div className="icon red one-x">
                      <img src="./../images/Search.png" alt="" />
                    </div>
                    <img
                      src="./../images/Group 818.png"
                      className="icon__step one"
                      alt=""
                    />
                  </div>

                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                    Discover
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                    Scroll through the eventsfeed and{" "}
                    <br className="d-none d-xl-block" /> discover what{" "}
                    <span className="extra-bold font__size--16 text__16-1024 text__16-md color__red">
                      last minute tickets are available in the next 48 hours
                    </span>
                  </p>
                </div>
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0 text-center position-relative"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  data-aos="fade-right"
                >
                  <div className="wrapper__image-step two position-relative">
                    <img
                      src="./../images/Arrow 3.png"
                      className="d-none d-lg-block arrow two"
                      data-aos-delay="700"
                      data-aos-duration="1000"
                      data-aos="fade-right"
                      alt=""
                    />
                    <div className="icon yellow two">
                      <img src="./../images/sdfsfsdf.png" alt="" />
                    </div>
                    <img
                      src="./../images/sdasadsad.png"
                      className="icon__step two"
                      alt=""
                    />
                  </div>

                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                    Decision Time
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                    It’s time to decide if you should secure{" "}
                    <br className="d-none d-xl-block" /> your ticket now, or
                    wait for a{" "}
                    <span className="extra-bold font__size--16 text__16-1024 text__16-md color__red">
                      PRICE DROP!
                    </span>
                  </p>
                </div>
                <div
                  className="col-md-6 col-lg-4 text-center position-relative"
                  data-aos-delay="900"
                  data-aos-duration="1000"
                  data-aos="fade-right"
                >
                  <div className="wrapper__image-step position-relative">
                    <div className="icon tosca three">
                      <img src="./../images/sfdsdfsf.png" alt="" />
                    </div>
                    <img
                      src="./../images/Group 813.png"
                      className="icon__step three"
                      alt=""
                    />
                  </div>

                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                    Grab your ticket & go!
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                    Speed and simplicity is key, especially{" "}
                    <br className="d-none d-xl-block" /> with last minute
                    tickets.{" "}
                    <span className="extra-bold font__size--16 text__16-1024 text__16-md color__red">
                      Click buy and <br className="d-none d-xl-block" /> have
                      your ticket sent instantly to your{" "}
                      <br className="d-none d-xl-block" /> inbox
                    </span>
                  </p>
                </div>
              </div>

              <div className="text-center mt-4">
                <a
                  href={WEB_APP_URL}
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                  className="bold font__size--16 text__16-1024 text__16-md color__white btn btn__red shadow btn__buy-step"
                >
                  View last minute events!
                </a>
              </div>
            </div>
          </section>

          <section className="bg__gray-6 position-relative">
            <img
              src="./../images/g900.png"
              className="path__9 d-none d-lg-block"
              alt=""
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center text-lg-left mb-4 my-lg-auto">
                  <h5
                    className="color__red ls__2 lh-2 extra-bold font__size--14 text__14-1024 line__left mb-0"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos="fade-right"
                  >
                    HOURLY PRICE DROPS...
                    <br className="br__400-block d-sm-none" />
                    TELL ME MORE?!
                  </h5>
                  <h4
                    className="extra-bold font__size--32 text__32-1024 text__32-1024 text__32-xs text__32-xxs color__black lh__5 my-4"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                  >
                    Have you ever thought...{" "}
                    <br className="br__400-block d-sm-none" />
                    “Why do tickets get more expensive as an event gets
                    closer?!”
                  </h4>
                  <p
                    className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                  >
                    Well we did too! We believe fans shouldn’t be penalised for
                    being spontaneous!
                  </p>
                  <p
                    className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                  >
                    So that’s why we have a price drop every hour,{" "}
                    <br className="br__400-block d-none" />
                    <span className="extra-bold color__red">
                      so you can expereince more, but pay less!
                    </span>
                  </p>
                  <p
                    className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2"
                    data-aos-delay="700"
                    data-aos-duration="1000"
                    data-aos="fade-up"
                  >
                    ...But, don’t wait around too long, otherwise another fan
                    will grab your ticket from right under your nose!
                  </p>
                </div>
                <div className="col-lg-6 text-center text-lg-right position-relative">
                  <img
                    src="./../images/g900.png"
                    className="path__9 d-lg-none"
                    alt=""
                  />
                  <div className="wrapper__image-side d-inline-block position-relative">
                    <img
                      src="./../images/g2574.png"
                      className="path__7"
                      data-aos-delay="700"
                      data-aos-duration="1000"
                      data-aos="fade-down-right"
                      alt=""
                    />
                    <img
                      src="./../images/g2870.png"
                      className="path__8"
                      data-aos-delay="600"
                      data-aos-duration="1000"
                      data-aos="fade-up-left"
                      alt=""
                    />

                    <div
                      className="icon"
                      data-aos-delay="600"
                      data-aos-duration="1000"
                      data-aos="fade-up-left"
                    >
                      <img src="./../images/das.png" alt="" />
                    </div>
                    <img src="./../images/Group 860 (1).png" alt="" />
                    <img src="./../images/sadsd.png" className="phone" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="text-center mb-5">
                <h4
                  className="extra-bold font__size--32 text__32-1024 color__black mb-4"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  Sell with ReTickin’
                </h4>
                <h5
                  className="color__red ls__5 semi-bold font__size--14 text__14-1024 lh-2"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  HOW TO SELL WITH RETICKIN’
                </h5>
              </div>

              <div className="row justify-content-center">
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0 text-center position-relative"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  data-aos="fade-right"
                >
                  <div className="wrapper__image-step one position-relative">
                    <img
                      src="./../images/Arrow 2.png"
                      className="arrow four d-none d-lg-block"
                      data-aos-delay="600"
                      data-aos-duration="1000"
                      data-aos="fade-right"
                      alt=""
                    />
                    <div className="icon red four">
                      <img src="./../images/sfdsdfsf.png" alt="" />
                    </div>
                    <img
                      src="./../images/fsddfs.png"
                      className="icon__step four"
                      alt=""
                    />
                  </div>

                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                    Easy as 1..2..3!
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                    <span className="extra-bold font__size--16 text__16-1024 text__16-md color__red">
                      Selling on ReTickin’ simple!{" "}
                    </span>{" "}
                    - upload <br className="d-none d-xl-block" /> your e-ticket,
                    set your listing price and{" "}
                    <br className="d-none d-xl-block" /> then wait patiently for
                    a last minute <br className="d-none d-xl-block" /> fan to
                    buy your ticket
                  </p>
                </div>
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0 text-center position-relative"
                  data-aos-delay="700"
                  data-aos-duration="1000"
                  data-aos="fade-right"
                >
                  <div className="wrapper__image-step two position-relative">
                    <img
                      src="./../images/Arrow 3.png"
                      className="arrow two d-none d-lg-block"
                      data-aos-delay="800"
                      data-aos-duration="1000"
                      data-aos="fade-right"
                      alt=""
                    />
                    <div className="icon tosca five">
                      <img src="./../images/Discount.png" alt="" />
                    </div>
                    <img
                      src="./../images/Group 861.png"
                      className="icon__step five"
                      alt=""
                    />
                  </div>

                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                    Automated Pricing
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                    <span className="extra-bold font__size--16 text__16-1024 text__16-md color__red">
                      List your ticket and we’ll do the rest!
                    </span>{" "}
                    <br className="d-none d-xl-block" />
                    Our pricing algorithm constantly{" "}
                    <br className="d-none d-xl-block" /> updates pricing, so we
                    can increase the <br className="d-none d-xl-block" />{" "}
                    chances of you selling your ticket and{" "}
                    <br className="d-none d-xl-block" /> getting the best payout
                  </p>
                </div>
                <div
                  className="col-md-6 col-lg-4 text-center position-relative"
                  data-aos-delay="900"
                  data-aos-duration="1000"
                  data-aos="fade-right"
                >
                  <div className="wrapper__image-step position-relative">
                    <div className="icon yellow six">
                      <img src="./../images/Wallet.png" alt="" />
                    </div>
                    <img
                      src="./../images/Group 863.png"
                      className="icon__step six"
                      alt=""
                    />
                  </div>
                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                    Zero Fees
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                    You are already missing out on the event,{" "}
                    <br className="d-none d-xl-block" /> so there's no need to
                    add insult to injury <br className="d-none d-xl-block" />{" "}
                    and charge you a fee to sell.{" "}
                    <span className="extra-bold font__size--16 text__16-1024 text__16-md color__red">
                      That's why <br className="d-none d-xl-block" /> there are
                      no seller fees
                    </span>
                    , should your <br className="d-none d-xl-block" /> ticket
                    sell at a price less that 115% of face value
                  </p>
                </div>
              </div>

              <div className="text-center mt-4">
                <a
                  href={WEB_APP_URL}
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                  className="bold font__size--16 text__16-1024 text__16-md color__white btn btn__red shadow btn__buy-step"
                >
                  View last minute events!
                </a>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
