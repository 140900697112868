import React from "react";

import { WEB_APP_URL } from "../configs/environments";
import "./mainSection.css";

const MainSection = () => {
  return (
    <section className="mainSection" id="click-buy-retickin">
      <div className="container">
        <div className="text-center mb-5">
          <h4
            className="extra-bold font__size--32 text__32-1024 color__black mb-4"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos="fade-up"
          >
            Why use ReTickin’
          </h4>
          <h5
            className="color__red ls__5 semi-bold font__size--14 text__14-1024 lh-2"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos="fade-up"
          >
            OUR BENEFIT FOR YOU
          </h5>
        </div>

        <div className="firstLine">
          <div
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos="fade-right"
          >

              <img
                src="/mainSection/Group 915.png"
                className="icon__step one firstCard"
                alt=""

              />

            {/**


                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                  Unlimited access
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                  Tickets to last-minute events today {" "}
                    <br className="d-none d-xl-block" /> and tomorrow{" "}

                  </p>
                  */}
          </div>
          <div

            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos="fade-right"
          >

              <img
                src="/mainSection/Group 919.png"
                className="icon__step one middleImg"
                alt=""

              />

            {/*


                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
                  Try for free!
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                  Try ReTickin' for free! You can cancel your {" "}
                    <br className="d-none d-xl-block" /> subscription at any time, so no long-term commitment{" "}

                  </p>
                  */}
          </div>
          <div

            data-aos-delay="900"
            data-aos-duration="1000"
            data-aos="fade-right"
          >

              <img
                src="/mainSection/Group 914.png"
                className="icon__step three"
                alt=""
              />
            {/**


                  <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3" style={{marginTop: '-35px'}}>
                  No Transaction Fees
                  </h4>
                  <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
                  Pay no transaction fee, buy as {" "}
                    <br className="d-none d-xl-block" /> many tickets as you want{" "}

                  </p>

                  */}
          </div>
        </div>
        <div className="firstLine second">
          <div

            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos="fade-right"
          >

              <img
                src="/mainSection/Group 917.png"
                className="icon__step one"
                alt=""

              />

        {/**


            <h4 className="extra-bold font__size--24 text__24-1024 color__black mb-3">
              Price Drops
            </h4>
            <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
              As the event approaches, ticket{" "}
              <br className="d-none d-xl-block" /> prices fall{" "}
            </p>
            */}
          </div>
          <div

            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos="fade-right"
          >

              <img
                src="/mainSection/Group 916.png"
                className="icon__step two"
                alt=""

              />

{
  /**


            <h4
              className="extra-bold font__size--24 text__24-1024 color__black mb-3"
              style={{ marginTop: "-22px" }}
            >
              ReTickin' Guarantee
            </h4>
            <p className="color__gray-2 semi-bold font__size--16 text__16-1024 text__16-md lh-2">
              You'll get a refund if you have{" "}
              <br className="d-none d-xl-block" /> any issues{" "}
            </p>
            */
}
          </div>
        </div>
        <div className="text-center mt-4">
          <a
            href={WEB_APP_URL}
            data-aos-delay="1000"
            data-aos-duration="1000"
            data-aos="fade-up"
            className="bold font__size--16 text__16-1024 text__16-md color__white btn btn__red shadow btn__buy-step"
          >
            View last minute events!
          </a>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
